import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import WordmarkWithGG from "@/inline-assets/wordmark-with-gg.svg";
import { getLocaleString } from "@/util/i18n-helper.mjs";

const Container = styled("div")`
  display: flex;
  align-items: stretch;
  gap: var(--sp-2);
  padding: 0 var(--sp-2);
  border-radius: var(--br) var(--br) 0 0;
  background: hsla(var(--shade10-hsl) / 0.6);
  border: 1px var(--shade4) solid;
  border-bottom: none;
  position: relative;

  > div {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  > section {
    padding: 0 var(--sp-2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:first-of-type {
      padding-left: var(--sp-4);
      &::before {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background: linear-gradient(
          to bottom,
          var(--shade4),
          hsla(var(--shade10-hsl) / 0)
        );
      }
    }
  }

  > svg {
    height: var(--sp-16);
    padding: var(--sp-2) 0;
  }
`;

const Title = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
  margin-bottom: var(--sp-2);
  padding-left: var(--sp-2);

  svg {
    display: inline-block;
    height: var(--sp-2_5);
  }
`;

const Matches = styled("div")`
  display: flex;
  gap: 1px;

  > div {
    flex: 1;
    border-radius: 0 0 var(--br-sm) var(--br-sm);
    text-align: center;
    &:first-of-type > div {
      border-left: 1px var(--shade4) solid;
    }
    &:last-of-type > div {
      border-right: 1px var(--shade4) solid;
    }
  }

  p {
    border-top: 3px transparent solid;
    background: linear-gradient(
      to bottom,
      hsla(var(--shade10-hsl) / 0.5),
      hsla(var(--shade10-hsl) / 0)
    );
    padding: var(--sp-1) 0;
  }
`;

const MatchContainer = styled("div")`
  img {
    display: block;
    max-width: var(--sp-7);
    height: auto;
    margin: auto;
    object-fit: cover;
    background: var(--shade10);
  }
`;

const MAX_MATCHES = 10;

export default function Variant2({ rank, rankScore, matches }) {
  const { t } = useTranslation();
  const damagePerMatch = Math.round(
    matches.reduce((acc, { damageDone: dmg }) => {
      return acc + dmg;
    }, 0) / matches.length || 0,
  );
  const background = rank
    ? `linear-gradient(to right, hsla(${rank.hsl} / 0.3), hsla(${rank.hsl} / 0))`
    : undefined;

  return (
    <>
      <Title>
        <WordmarkWithGG />
      </Title>
      <Container>
        <div style={{ background }} />
        {rank && <rank.iconLg />}
        <section>
          <div className="type-subtitle1">
            {t("apex:stats.rpWithPoints", "{{rp, number}} RP", {
              rp: rankScore,
            })}
          </div>
          <div className="type-caption shade2">
            {rank &&
              t(rank.tKey, rank.tDefault, {
                tier: rank.rank,
              })}
          </div>
        </section>
        <section>
          <div className="type-subtitle1">
            {getLocaleString(damagePerMatch)}
          </div>
          <div className="type-caption shade2">
            {t("common:stats.damagePerMatch", "DMG/Match")}
          </div>
        </section>
      </Container>
      <Matches>
        {matches
          .slice(0, MAX_MATCHES)
          .map(({ placement, legendImage, legendName }, i) => {
            const label = {
              __html: t("common:stats.placement", "{{placement, ordinal}}", {
                placement,
              }).replace(/([a-z]*)/gi, "<sup>$1</sup>"),
            };

            const borderColor = (() => {
              let result = "var(--shade4)";
              if (placement === 1) result = "hsla(var(--rank-gold-hsl) / 1)";
              if (placement === 2) result = "hsla(var(--rank-gold-hsl) / 0.5)";
              if (placement === 3) result = "hsla(var(--rank-gold-hsl) / 0.2)";
              return result;
            })();

            const background = (() => {
              let result;
              if (placement === 1)
                result =
                  "linear-gradient(to bottom, hsla(var(--rank-gold-hsl) / 0.8), hsla(var(--rank-gold-hsl) / 0))";
              if (placement === 2)
                result =
                  "linear-gradient(to bottom, hsla(var(--rank-gold-hsl) / 0.4), hsla(var(--rank-gold-hsl) / 0))";
              if (placement === 3)
                result =
                  "linear-gradient(to bottom, hsla(var(--rank-gold-hsl) / 0.2), hsla(var(--rank-gold-hsl) / 0))";
              return result;
            })();

            return (
              <MatchContainer className={`type-mini shade0`} key={i}>
                <div>
                  <img src={legendImage} alt={legendName} />
                </div>
                <p
                  style={{ borderColor, background }}
                  dangerouslySetInnerHTML={label}
                />
              </MatchContainer>
            );
          })}
      </Matches>
    </>
  );
}
