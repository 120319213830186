import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import { LEGENDS } from "@/game-apex/constants.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import Variant1 from "@/game-apex/StreamerOverlayVariant1.jsx";
import Variant2 from "@/game-apex/StreamerOverlayVariant2.jsx";
import { getCurrentRank } from "@/game-apex/utils/get-division.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Wrapper = styled("div")`
  width: 100%;
  text-shadow: 0 1px var(--sp-2) hsla(var(--shade10-hsl) / 0.6);

  > div {
    display: inline-block;
  }
`;

const variantMap = {
  variant1: Variant1,
  variant2: Variant2,
};

export default function StreamerOverlay() {
  const { t } = useTranslation();
  const elRef = useRef(null);
  const { searchParams } = useRoute();
  const Variant =
    variantMap[searchParams.get("variant")] ?? Object.values(variantMap)[0];
  const apexPlatformId =
    searchParams.get("apexPlatformId") || searchParams.get("id");
  const state = useSnapshot(readState);

  useLayoutEffect(() => {
    // Doing some manual DOM stuff that is outside the scope of React
    const e = elRef.current;
    if (!e) return;
    const obs = new ResizeObserver(([entry]) => {
      const {
        contentBoxSize: [{ inlineSize: w, blockSize: h }],
      } = entry;
      setVolatileKV("streamerDimensions", { w, h });
    });
    obs.observe(e);
    return () => {
      obs.disconnect();
    };
  }, []);

  const {
    apex: { playerStats },
  } = state;

  const rankScore = playerStats[apexPlatformId]?.ALL?.RANKED?.rankedPoints;
  const rank = getCurrentRank(rankScore);
  const kills = playerStats[apexPlatformId]?.ALL?.RANKED?.kills;
  const attribution = globals.location?.host;

  const matchlist = state.apex.matchlists[apexPlatformId]?.ALL?.ALL ?? [];
  const matches = matchlist
    .map(({ apexId }) => {
      const match = state.apex.matches[apexId];
      if (!match) return null;
      const player = (match.playerMatchStats || []).find(
        ({ platformId }) => platformId === apexPlatformId,
      );
      if (!player) return null;
      const { team: { placement } = {}, champion: { apexId: legendId } = {} } =
        player;
      const legendImage = staticMediaURLs.getLegendImage(legendId);
      const legendName = LEGENDS[legendId]?.name;
      return { ...player, placement, legendImage, legendName };
    })
    .filter(Boolean);

  const props = {
    attribution,
    rank,
    rankScore,
    kills,
    matches,
  };

  const hasData = Boolean(matchlist.length);

  return (
    <Wrapper className="apex-streamer">
      <div ref={elRef}>
        {hasData ? (
          <Variant {...props} />
        ) : (
          t("common:noPlayerData", "No Player Data Found")
        )}
      </div>
    </Wrapper>
  );
}

export function meta() {
  return {
    title: ["overlays:streamerOverlay.title", "Streamer Overlay"],
    description: [
      "overlays:streamerOverlay.description",
      "Show your stats in streaming software, such as OBS Studio.",
    ],
  };
}
